export const PaymentChannelDesc = {
  wx_pub: '微信',
  wx: '微信',
  wx_pub_qr: '微信',
  wx_wap: '微信',
  alipay: '支付宝',
  alipay_wap: '支付宝',
  alipay_pc_direct: '支付宝',
  alipay_qr: '支付宝',
}
export const UserType = {
  notLoggedIn: 'not loggedIn',
  pendingManager: 'pending manager',
  user: 'user',
  agent: 'agent',
  manager: 'manager',
}
