import React, { useState } from 'react'
import { Grid, Card, CardContent, Tooltip, Container } from '@material-ui/core'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import SnackMessage from '../components/snackbar'
import Nav from '../components/nav'
import Image from '../components/Image'

const Manual = () => {
  const [open, setOpen] = useState(false)
  const [resMessage, setResMessage] = useState('')
  return (
    <Nav>
      <Container fixed maxWidth="sm">
        <Grid container>
          <Grid item xs className="py-24">
            <Card>
              <CardContent>
                <div className="text-center font-bold text-2xl mb-6">浏览器配置说明</div>
                <div className="text-sm">第一步：在应用中心内搜索并安装Switchyomega插件，以360极速浏览器为例</div>
                <span className="text-sm">
                  （点击下载
                  <a href="https://st00.xyz/download/SwitchyOmega_Chromium.zip" className="text-red-600">
                    SwitchyOmega
                  </a>
                  插件）
                </span>
                <Image fileName="omega1.png" className="my-3" alt="" />
                <div className="text-sm">第二步：进入插件配置【导入/导出】界面, 添加在线恢复文件后点击恢复即可</div>
                <CopyToClipboard
                  text="https://st00.xyz/download/QYTOmegaOptions.bak"
                  onCopy={() => {
                    setOpen(true)
                    setResMessage('链接已复制')
                  }}
                >
                  <div className="cursor-pointer text-base text-red-600 mt-3">
                    <Tooltip title="点击复制链接" placement="top">
                      <div className="truncate">https://st00.xyz/download/QYTOmegaOptions.bak</div>
                    </Tooltip>
                  </div>
                </CopyToClipboard>
                <div className="text-center my-3">
                  <Image fileName="omega2.png" alt="" />
                </div>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <SnackMessage open={open} onClose={() => setOpen(false)} message={resMessage} status="success" />
      </Container>
    </Nav>
  )
}

export default Manual
