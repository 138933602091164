import React, { useState } from 'react'
import { Drawer, Box, List, ListItem } from '@material-ui/core'
import { navigate, graphql, StaticQuery, Link } from 'gatsby'
import SEO from 'components/SEO'
import localStorage from 'localStorage'
import { UserType } from 'consts'
import { logout } from 'http/self'

const Nav = ({ children }) => {
  const [visible, setVisible] = useState(false)
  const rightIconMenuNotLoggedIn = () => {
    return (
      <List>
        {[
          ['/login', '登录'],
          ['/signup', '注册'],
        ].map((v) => (
          <ListItem button onClick={() => setVisible(false)} key={v[0]}>
            <Link to={v[0]}>{v[1]}</Link>
          </ListItem>
        ))}
      </List>
    )
  }

  const horizontalMenuNotLoggedIn = () => {
    return (
      <ul className="nav navbar-nav font-bold">
        {[
          ['/login', '登录'],
          ['/signup', '注册'],
        ].map((v) => (
          <li className="mega-menu" key={v[0]}>
            <Link to={v[0]}>{v[1]}</Link>
          </li>
        ))}
      </ul>
    )
  }

  const horizontalMenuLoggedIn = () => {
    return (
      <ul className="nav navbar-nav font-bold">
        {localStorage.getItem('USER_TYPE') === UserType.manager ? (
          <li className="mega-menu">
            <Link to="/main/overview">控制台</Link>
          </li>
        ) : null}
        {localStorage.getItem('USER_TYPE') === UserType.agent ? (
          <li className="mega-menu">
            <Link to="/agent/marketing">控制台</Link>
          </li>
        ) : null}
        <li className="mega-menu">
          <a>
            <span className="cursor-pointer" role="presentation" onClick={() => logout()}>
              登出
            </span>
          </a>
        </li>
      </ul>
    )
  }

  const rightIconMenuLoggedIn = () => {
    return (
      <List>
        {localStorage.getItem('USER_TYPE') === UserType.manager ? (
          <Link to="/main/overview">
            <ListItem button onClick={() => setVisible(false)}>
              控制台
            </ListItem>
          </Link>
        ) : null}
        {localStorage.getItem('USER_TYPE') === UserType.agent ? (
          <Link to="/agent/marketing">
            <ListItem button onClick={() => setVisible(false)}>
              <a>控制台</a>
            </ListItem>
          </Link>
        ) : null}
        <ListItem button onClick={() => logout()}>
          <span>登出</span>
        </ListItem>
      </List>
    )
  }

  return (
    <StaticQuery
      query={graphql`
        query NavQuery {
          site {
            siteMetadata {
              title
              keywords
              description
              favicon
            }
          }
        }
      `}
      render={(data) => {
        const {
          site: {
            siteMetadata: { title, keywords, description, favicon },
          },
        } = data
        return (
          <Box bgcolor="background.paper" color="text.primary">
            <SEO title={title} keywords={keywords} description={description} favicon={favicon} />
            <nav className="main-nav menu-transparent">
              <div className="container">
                <div className="navbar">
                  <div className="brand-logo cursor-pointer">
                    <div role="presentation" className="navbar-brand cursor-pointer no-underline" onClick={() => navigate('/')}>
                      <div className="logo-qjs" />
                    </div>
                  </div>
                  <div className="navbar-header">
                    <div className="inner-nav">
                      <ul>
                        <li className="navbar-toggle">
                          <a className="no-underline">
                            <i role="presentation" className="text-xl iconfont icon-menu cursor-pointer" onClick={() => setVisible(true)} />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <Drawer anchor="right" open={visible} onClose={() => setVisible(false)}>
                    {localStorage.getItem('Token') ? rightIconMenuLoggedIn() : rightIconMenuNotLoggedIn()}
                  </Drawer>
                  <div className="navbar-collapse collapse inner-nav">{localStorage.getItem('Token') ? horizontalMenuLoggedIn() : horizontalMenuNotLoggedIn()}</div>
                </div>
              </div>
            </nav>
            <div className="min-h-screen">{children}</div>
          </Box>
        )
      }}
    />
  )
}

export default Nav
